import React from 'react';
import moment from 'moment';
const InputField = (props) => {
  return (
    <div id={props.idName}>
      {/* <label htmlFor={props.label}>{props.label}</label> */}
      {props.type === 'date' || props.type === 'time' ? (
        <input
          className={props.className}
          type={props.type}
          name={props.name}
          id={props.id}
          placeholder={props.placeholder}
          value={props.value}
          style={{ height: '50px'}}
          onChange={(e) => {
            props.change(e.target.value);
          }}
          min={
            props.type === 'date' &&
            props.allow === false &&
            moment().format('MM/DD/YYYY')
          }
          max={
            props.type === 'date' &&
            props.allow === false &&
            moment().format('MM/DD/YYYY')
          }
        />
      ) : (
        <input
          className={props.className}
          type={props.type}
          name={props.name}
          id={props.id}
          placeholder={props.placeholder}
          value={props.value}
          style={{ height: '50px'}}
          onChange={(e) => {
            props.change(e.target.value);
          }}
        />
      )}
    </div>
  );
};
export default InputField;
